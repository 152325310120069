document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	hideElement();
	swiperInit();
	setBackground();
	counterAnimate();
	generalFnc();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1024.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const nav = document.querySelector(".side-nav-tool");
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		nav.classList.add("show");
	} else {
		nav.classList.remove("show");
	}
	if ($(window).width() > 1200.1) {

		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		};
	}
});
document.addEventListener("click", function (e) {
	const shoppingCartToggle = document.querySelector(".shopping-cart-toggle");
	const shoppingCartWrapper = document.querySelector(
		".shopping-cart-wrapper"
	);

	if (
		!shoppingCartToggle.contains(e.target) &&
		!shoppingCartWrapper.contains(e.target)
	) {
		shoppingCartWrapper.classList.remove("open");
	}
});
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});



function generalFnc() {
	//Toggle Javascript code

	const bodyWrap = document.querySelector("body");
	const menuToggle = document.querySelector(".menu-toggle");

	const hamburger = document.querySelector(".hamburger");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	// const searchToggle = document.querySelector(".search-toggle");
	const search = document.querySelector(".searchbox");
	const backTop = document.querySelector(".back-to-top");

	//const Cart
	const cart = document.querySelector(".shopping-cart-wrapper");
	const cartIconToggle = document.querySelector(
		".shopping-cart-toggle .cart-icon"
	);

	// Menu function

	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});

	//Cart Function

	cartIconToggle.addEventListener("click", (e) => {
		cart.classList.toggle("open");
		e.stopPropagation();
	});

	if ($(window).width() < 1200) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
		$(".tertiary-menu-toggle").on("click", function () {
			$(".nav-tertiary-menu").slideToggle();
		});
	}

	//Commnet append

	if ($("body").hasClass("product-detail-page")) {
		if ($(".commentpanel").length) {
			$(".commentpanel").appendTo(".product-rating-wrap");
		}
	}

	document.addEventListener("click", (event) => {
		if (!event.target.closest(".search-toggle, .searchbox")) {
			search.classList.remove("open");
		}
		if (!event.target.closest(".mobile-nav-wrap, .menu-toggle")) {
			menuMobile.classList.remove("is-open");
			bodyWrap.classList.remove("overlay-bg");
			hamburger.classList.remove("is-active");
		}
	});
	//Side Nav  and back to top
	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	//Tab function

	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents(".tab-panel").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}

function swiperInit() {
	$(".home-product-swiper  .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-grid-" + index);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-grid-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-grid-" + index);
		var swiper = new Swiper(".single-grid-" + index, {
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 25,
			observeParents: true,
			slidesPerView: 2,
			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 3,
				},
				1024: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
					grid: {
						rows: 2,
					},
				},
			},
			navigation: {
				nextEl: ".btn-next-grid-" + index,
				prevEl: ".btn-prev-grid-" + index,
			},
		});
	});

	$(".block-swiper-nav  .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("nav-intance-" + index);
		$this
			.parents('.block-swiper-nav')
			.find(".nav-prev")
			.addClass("prev-inst-" + index);
		$this
			.parents('.block-swiper-nav')
			.find(".nav-next")
			.addClass("next-inst-" + index);

		var swiper = new Swiper(".nav-intance-" + index, {
			preventInteractionOnTransition: true,
			slideToClickedSlide: true,
			slidesPerView: "auto",
			speed: 705,
			breakpoints: {
				320: {
					spaceBetween: 10,
				},
				576: {
					spaceBetween: 20,
				},
			},
			lazy: {
				loadPrevNext: true,
			},
			navigation: {
				nextEl: ".next-inst-" + index,
				prevEl: ".prev-inst-" + index,
			},
		});
	});

	var productThumbs = new Swiper(".swiper-product-thumb  .swiper", {
		spaceBetween: 8,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			768.1: {
				spaceBetween: 15,
				slidesPerView: 5,
			},
			1024.1: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			1600.1: {
				spaceBetween: 25,
				slidesPerView: 4,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".product-swiper-wrap  .nav-next",
			prevEl: ".product-swiper-wrap  .nav-prev",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});

	var homeZone = new Swiper(".home-zone-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
			},
			1200: {
				slidesPerView: 6,
			},
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".home-zone-swiper .nav-next",
			prevEl: ".home-zone-swiper .nav-prev",
		},
	});
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},

		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			renderBullet: function (index, className) {
				var titleList = [];
				$(".primary-banner .swiper-slide").each(function (i) {
					let temp = $(this).find(".title").text();
					titleList.push(temp);
				});
				return (
					'<div class="' +
					className +
					'">' +
					"<span class='pagination-title'>" +
					titleList[index] +
					"</span></div>"
				);
			},
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single " + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 20,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function counterAnimate() {
	const counterItem = document.querySelectorAll(".counter");
	if (counterItem.length) {
		const counterUp = window.counterUp.default;
		const callback = (entries) => {
			entries.forEach((entry) => {
				const el = entry.target;
				if (
					entry.isIntersecting &&
					!el.classList.contains("is-visible")
				) {
					counterUp(el, {
						duration: 2000,
						delay: 200,
					});
					el.classList.add("is-visible");
				}
			});
		};
		const IO = new IntersectionObserver(callback, { threshold: 1 });

		counterItem.forEach((counter) => {
			IO.observe(counter);
		});
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".account-wrapper",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".search-wrapper",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".nav-brand",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".nav-brand",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();

	new MappingListener({
		selector: ".nav-secondary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".account-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [account,search, menu, language,nav] = [
			".account-wrapper",
			".searchbox",
			".nav-primary-menu",
			".language-wrapper",
			".nav-secondary-menu"
		].map((selector) => document.querySelector(selector));

		[account,search, menu, language,nav].forEach((el) => (el.style.display = "flex"));
	}
}
